/**
 * Customer Notification API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface XpoLtlWebCustomerNotificationSubscriptionSharedModelsSubscriptionsEventItem {
    eventId?: number;
    appId?: string | null;
    eventType?: string | null;
    applicationReferenceType?: string | null;
    isSubscribable?: boolean;
    emailNotificationSenderName?: string | null;
    emailNotificationSenderEmail?: string | null;
}

