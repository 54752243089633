import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoAuthConfig, XPO_AUTH_CONFIG } from '@xpo-ltl/ngx-auth';
import { Observable } from 'rxjs';
import { FeaturePermission, UserBulkRequest, UserBulkResponse } from '../interface/partner-master-api.interface';
import {
  Accounts,
  FetchPartnersRequest,
  FetchPartnersResponseObject,
  GroupType,
  UserAccountRequest,
} from '../model/fetch-partners';
import {
  FetchUsersResponse,
  FetchUsersResponseItem,
  RoleLookup,
  UserAccess,
  UserAccessResponse,
  UserAccessRoleRequest,
  UserPassword,
  UserPermissionResponse,
  UserStatus,
} from '../model/fetch-users';
import { RequestObject } from '../model/request-object';
import { UserDetailsResponse } from '../model/user-details';
import { FetchSyncJobItem, SyncLegacy, UpdateFailedSyncJob } from '../model/failed-sync-jobs';

@Injectable({
  providedIn: 'root',
})
export class PartnerMasterService {
  private _loggedInUser!: string;

  constructor(
    private configManagerService: ConfigManagerService,
    private http: HttpClient,
    @Inject(XPO_AUTH_CONFIG) authConfig: XpoAuthConfig
  ) {
    const authorityUrl = authConfig.authorityUri;
    const clientId = this.configManagerService.getSetting('appCode');
    const sessionVariable = `oidc.user:${authorityUrl}:${clientId}`;
    const currentUser = JSON.parse(<string>sessionStorage.getItem(sessionVariable));
    this.loggedInUser = currentUser ? currentUser.profile.email : '';
  }

  get loggedInUser(): string {
    return this._loggedInUser;
  }

  set loggedInUser(user: string) {
    this._loggedInUser = user;
  }

  get apiUrl(): string {
    return this.configManagerService.getSetting('xeaApiUrl');
  }

  fetchMasterAccount(request: FetchPartnersRequest): Observable<FetchPartnersResponseObject> {
    const url = this.apiUrl + `sulu/backend/partnermaster/search/partners`;
    return this.http.post<FetchPartnersResponseObject>(url, request);
  }

  getRoleList(): Observable<RoleLookup[]> {
    const url = this.apiUrl + 'sulu/backend/partnermaster/RolesLookup';
    return this.http.get<RoleLookup[]>(url);
  }
  getSuluRoleList(): Observable<RoleLookup[]> {
    const url = this.apiUrl + 'sulu/backend/usermanagement/roles';
    return this.http.get<RoleLookup[]>(url);
  }
  getGroupList(): Observable<GroupType[]> {
    const url = this.apiUrl + 'sulu/backend/partnermaster/partnergrouplookup';
    return this.http.get<GroupType[]>(url);
  }

  fetchUsers(request: RequestObject): Observable<FetchUsersResponse> {
    const url = this.apiUrl + `sulu/backend/partnermaster/search/users`;
    return this.http.post<FetchUsersResponse>(url, request);
  }

  fetchDetails(userName: string): Observable<UserDetailsResponse> {
    const url = this.apiUrl + `sulu/backend/partnermaster/user/${userName}`;
    return this.http.get<UserDetailsResponse>(url);
  }

  getUserAccess(userName: string): Observable<UserAccess> {
    const url = this.apiUrl + `sulu/backend/partnermaster/getUserAccess/${userName}`;
    return this.http.get<UserAccess>(url);
  }

  getUserDetails(): Observable<UserPermissionResponse> {
    const url = this.apiUrl + `sulu/backend/usermanagement/getuserdetails`;
    return this.http.get<UserPermissionResponse>(url);
  }

  userTemplateDownload(request: RequestObject): Observable<any> {
    const apiUrl = this.apiUrl + 'sulu/backend/partnermaster/downloaduserdata';
    return this.http.post(apiUrl, request);
  }

  userBulkDeactivate(request: UserBulkRequest): Observable<UserBulkResponse> {
    const apiUrl = this.apiUrl + 'sulu/backend/partnermaster/bulkuserstatusupdate';
    return this.http.put<UserBulkResponse>(apiUrl, request);
  }

  userBulkResetPassword(request: UserBulkRequest): Observable<UserBulkResponse> {
    const apiUrl = this.apiUrl + 'sulu/backend/partnermaster/bulkresetpassword';
    return this.http.put<UserBulkResponse>(apiUrl, request);
  }

  setUserStatus(request: UserStatus): Observable<boolean> {
    const url = this.apiUrl + `sulu/backend/partnermaster/userStatusUpdate`;
    return this.http.put<boolean>(url, request);
  }

  setUserPassword(request: UserPassword): Observable<boolean> {
    const url = this.apiUrl + `sulu/backend/partnermaster/userPassword`;
    return this.http.put<boolean>(url, request);
  }

  sendPasswordReminder(username: string): Observable<boolean> {
    const url = this.apiUrl + `sulu/backend/partnermaster/sendPasswordReminder/${username}`;
    return this.http.put<boolean>(url, username);
  }

  createUser(request: UserAccess): Observable<UserAccessResponse> {
    const url = this.apiUrl + `sulu/backend/partnermaster/upsert`;
    return this.http.post<UserAccessResponse>(url, request);
  }

  fetchPartners(request: FetchPartnersRequest): Observable<FetchPartnersResponseObject> {
    const url = this.apiUrl + `sulu/backend/partnermaster/search/partners`;
    return this.http.post<FetchPartnersResponseObject>(url, request);
  }

  saveUser(request: UserAccessRoleRequest): Observable<UserAccessResponse> {
    request.updateBy = this.loggedInUser;
    const apiUrl = this.apiUrl + `sulu/backend/usermanagement/upsertUserAccessRole`;
    return this.http.post<UserAccessResponse>(apiUrl, request);
  }

  getAdminUser(): Observable<FetchUsersResponseItem[]> {
    const url = this.apiUrl + `sulu/backend/usermanagement/getusers`;
    return this.http.get<FetchUsersResponseItem[]>(url);
  }

  getUserInfo(userName: string): Observable<UserPermissionResponse> {
    const url = this.apiUrl + `sulu/backend/usermanagement/getuserInfo/${userName}`;
    return this.http.get<UserPermissionResponse>(url);
  }

  getDomainMismatchAccounts(userName: string): Observable<Accounts[]> {
    const url = this.apiUrl + `sulu/backend/partnermaster/getDomainMismatchAccounts/${userName}`;
    return this.http.get<Accounts[]>(url);
  }

  getRestrictedAccounts(userName: string): Observable<Accounts[]> {
    const url = this.apiUrl + `sulu/backend/partnermaster/getRestrictedAccounts/${userName}`;
    return this.http.get<Accounts[]>(url);
  }

  getFailedSyncJobs(userName: string | null = null): Observable<FetchSyncJobItem[]> {
    const url = new URL(this.apiUrl + `sulu/backend/partnermaster/getSyncFailedDetails`);

    if (userName) {
      url.searchParams.append('userName', userName);
    }

    return this.http.get<FetchSyncJobItem[]>(url.toString());
  }

  getFailedSyncPartnerDetails(userName: string): Observable<FetchSyncJobItem[]> {
    const url = this.apiUrl + `sulu/backend/partnermaster/getSyncFailedPartnerDetails/${userName}`;
    return this.http.get<FetchSyncJobItem[]>(url);
  }

  updateFailedSyncJob(request: UpdateFailedSyncJob): Observable<UserAccessResponse> {
    const url = this.apiUrl + `sulu/backend/usermanagement/updateFailedSyncData`;
    return this.http.put<UserAccessResponse>(url, request);
  }

  userAccountRequest(request: UserAccountRequest): Observable<UserAccessResponse> {
    const url = this.apiUrl + `sulu/backend/partnermaster/approveOrDeclineAccountRequests`;
    return this.http.post<UserAccessResponse>(url, request);
  }

  getFeature(featureName: string): Observable<FeaturePermission[]> {
    const url = this.apiUrl + `sulu/backend/partnermaster/getFeature/${featureName}`;
    return this.http.get<FeaturePermission[]>(url);
  }

  syncLegacy(request: SyncLegacy): Observable<UserAccessResponse> {
    const url = this.apiUrl + `sulu/backend/partnermaster/stageLegacyEvent`;
    return this.http.put<UserAccessResponse>(url, request);
  }
}
