<div class="eso-Alerts">
  <div class="eso-Alerts-statusIcon">
    <mat-icon>{{ getIcon }}</mat-icon>
  </div>
  <div class="eso-Alerts-message">
    <span>{{ data.message }}</span>
  </div>
  <div>
    <button class="eso-Alerts-close" mat-icon-button (click)="close()">
      <mat-icon> close </mat-icon>
    </button>
  </div>
</div>
