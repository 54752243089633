/**
 * Customer Notification API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface XpoLtlWebCustomerNotificationSubscriptionSharedModelsIApplicationReference {
    readonly applicationReferenceType?: string | null;
    customerNumbers?: string[] | [];
    madCodes?: string[] | [];
    proNumbers?: string[] | [];
    timeOfDay?: number | null;
    dayOfWeek?: number | null;
    payload?: any | null;
    isCustom?: boolean | null;
}

