import { ResponseObject } from './response-object';

export interface FetchUsersResponse extends ResponseObject {
  items: FetchUsersResponseItem[];
}

export interface FetchUsersResponseItem {
  userId: number;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  preferredCurrencyCode: string;
  preferredLanguageCode: string;
  preferredSystemOfMeasureCode: string;
  userStatus: string;
  lastLoggedInDate: string;
  countryCode: string;
  communicationPreferenceId: number;
  companyName: string;
  isAdmin: boolean;
  address1: string;
  address2: string;
  stateCode: string;
  city: string;
  postalCode: string;
  createdBy: string;
  createdOn: string;
  lastUpdatedBy: string;
  lastUpdatedOn: string;
  isDomainMismatch: true;
  masterAccounts: MasterAccount[];
  isActive: string;
}

export interface MasterAccount {
  userId: number;
  //partnerId: number;
  partnerIdentifier: string;
  displayName: string;
  userRole: {
    //partnerId: number;
    roleName: string;
    roleCode: string;
    applicationCode: string;
    isXpoInternal: true;
    userTypeCode: string;
    userTypeDesc: string;
    systemCode: string;
  };
  partnerGroups: {
    //partnerId: number;
    partnerIdentifier: string;
    partnerGroupId: number;
    groupName: string;
    groupTypeName: string;
  };
}

export interface RoleLookup {
  roleId?: number;
  roleCode?: string;
  roleName?: string;
  roleDescription?: string;
  partnerTypeId?: number;
  businessUnitCode?: string;
  modeCode?: string;
  systemCode?: string;
  isEnabled?: boolean;
  isXpoOnly?: boolean;
  isCustomRole?: boolean;
}

export interface UserAccess {
  firstName?: string;
  lastName?: string;
  email?: string;
  userName: string;
  country?: string;
  phoneNumber?: string;
  address1?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  accessLevel?: number;
  companyName?: string;
  lastLoggedInDate?: string;
  createdOn?: string;
  lastUpdatedOn?: string;
  locations?: UserAccessLocations[];
  isActive?: boolean;
  requestType?: string;
  loggedInUser?: string;
  isDomainMismatch?: boolean;
  isRestricted?: boolean;
  isDeleted?: boolean;
  profileInstanceId?: string;
  betaTesterLevel?: number;
  isCalledFromAdmin?: boolean;
  passwordUpdatedBy?: string;
  passwordUpdatedOn?: string;
}

export interface UserAccessLocations {
  locationId?: number;
  madCode?: string;
  group?: Groups[];
  accessLevel?: AccessLevel[];
}

export interface Groups {
  groupId?: number;
  groupName?: string;
}

export interface AccessLevel {
  roleId?: number;
  roleName?: string;
  roleCode?: string;
}

export interface UserPermissionResponse {
  userId?: number;
  email?: string;
  firstName?: string;
  lastName?: string;
  businessUnitId?: number;
  isActive?: boolean;
  roles?: AccessLevel[];
  permissions?: Permissions[];
  createdOn?: string;
}

export interface Permissions {
  permissionId?: number;
  permissionName?: string;
  permissionCode?: string;
}

export interface UserStatus {
  statusflag: string;
  updatedBy: string;
  username: string;
}

export interface UserPassword {
  password: string;
  username: string;
}

export interface UserAccessResponse {
  message: string;
  isSuccess?: boolean;
}

export interface UserAccessRoleRequest {
  email: string;
  firstName: string;
  isActive: boolean;
  lastName: string;
  updateBy: string;
  userId: number;
  userName: string;
}
