export enum AppRouteNames {
  alertsAndExceptions = 'alerts-and-exceptions',
  forms = 'forms',
  partnerMaster = 'partner-manager',
  admin = 'admin',
  userOnboarding = 'user-onboarding',
  manageSyncJob = 'manage-sync-job',
  webContentManagement = 'web-content-management',
  webDeveloperPortal = 'web-developer-portal',
}

export const AppRouteAccess: Record<
  AppRouteNames,
  (params: { isAdmin: boolean; isProd: boolean; isUat: boolean }) => boolean
> = {
  [AppRouteNames.alertsAndExceptions]: (params) => true,
  [AppRouteNames.forms]: (params) => true,
  [AppRouteNames.partnerMaster]: (params) => !params.isProd || (params.isProd && params.isAdmin),
  [AppRouteNames.userOnboarding]: (params) => !params.isProd || (params.isProd && params.isAdmin),
  [AppRouteNames.admin]: (params) => !params.isProd || (params.isProd && params.isAdmin),
  [AppRouteNames.manageSyncJob]: (params) => !params.isProd || (params.isProd && params.isAdmin),
  [AppRouteNames.webContentManagement]: (params) => !params.isProd || (params.isProd && params.isAdmin),
  [AppRouteNames.webDeveloperPortal]: (params) => !params.isProd || (params.isProd && params.isAdmin),
};

export const APP_ROUTE_PATHS = {
  partnerManager: {
    manageSyncJob: `/${AppRouteNames.partnerMaster}/${AppRouteNames.manageSyncJob}`,
  },
};

export enum ManageSyncJobQueryParams {
  userName = 'userName',
}
