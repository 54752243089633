/**
 * Customer Notification API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface XpoConnectBillingServiceModelsV2PaymentElasticPaymentItem { 
    creditCardTransactionId?: number;
    madCode?: string | null;
    accountName?: string | null;
    amountPaid?: number;
    amountOwed?: number;
    transactionFee?: number;
    currencyCode?: string | null;
    partialPaymentReasonCode?: string | null;
    paymentMethod?: string | null;
    paidBy?: string | null;
    paidOnDate?: Date;
    referenceNumbers?: Array<string> | null;
    status?: string | null;
    proNumbers?: Array<string> | null;
    isPartialPayment?: boolean;
}

