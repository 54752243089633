/**
 * Customer Notification API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { XpoLtlWebCustomerNotificationSubscriptionSharedModelsRequestsBccRecipients } from './xpoLtlWebCustomerNotificationSubscriptionSharedModelsRequestsBccRecipients';
import { XpoLtlWebCustomerNotificationSubscriptionSharedModelsSubscriptionsPilotReference } from './xpoLtlWebCustomerNotificationSubscriptionSharedModelsSubscriptionsPilotReference';


export interface XpoLtlWebCustomerNotificationSubscriptionSharedModelsRequestsUpsertEventRequest {
    eventId?: number;
    appId?: string | null;
    eventType?: string | null;
    description?: string | null;
    counter?: number;
    lastOccurrence?: Date | null;
    isVisibleToUser?: boolean;
    isAdminManagementEnabled?: boolean;
    isSubscribable?: boolean;
    userName?: string | null;
    deleted?: boolean;
    pilotReferences?: Array<XpoLtlWebCustomerNotificationSubscriptionSharedModelsSubscriptionsPilotReference> | null;
    bccRecipients?: XpoLtlWebCustomerNotificationSubscriptionSharedModelsRequestsBccRecipients;
    emailNotificationSenderName?: string | null;
    emailNotificationSenderEmail?: string | null;
}

