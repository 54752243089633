/**
 * Customer Notification API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { XpoLtlWebCustomerNotificationSubscriptionSharedModelsIApplicationReference } from './xpoLtlWebCustomerNotificationSubscriptionSharedModelsIApplicationReference';
import { XpoLtlWebCustomerNotificationSubscriptionSharedModelsRecipientsBroadcastRecipient } from './xpoLtlWebCustomerNotificationSubscriptionSharedModelsRecipientsBroadcastRecipient';


export interface XpoLtlWebCustomerNotificationSubscriptionSharedModelsSubscriptionsEventSubscription { 
    createdBy?: string | null;
    createdOn?: Date;
    updatedBy?: string | null;
    updatedOn?: Date;
    subscriptionId?: number;
    subscriptionName?: string | null;
    appId?: string | null;
    eventType?: string | null;
    eventId?: number;
    applicationReferenceType?: string | null;
    isVisibleToUser?: boolean;
    isActive?: boolean;
    broadcastRecipients?: Array<XpoLtlWebCustomerNotificationSubscriptionSharedModelsRecipientsBroadcastRecipient> | null;
    deleted?: boolean;
    username?: string | null;
    applicationReference?: XpoLtlWebCustomerNotificationSubscriptionSharedModelsIApplicationReference;
    eventGroupCode?: string | null;
}

