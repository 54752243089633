export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBIo-Gnt7bOdJLouRxWEeDzmBkI-h90JjY',
    authDomain: 'xpo-ltl-dev-cf3b6.firebaseapp.com',
    databaseURL: 'https://xpo-ltl-dev-cf3b6.firebaseio.com',
    projectId: 'xpo-ltl-dev',
    storageBucket: 'xpo-ltl-dev.appspot.com',
    messagingSenderId: '339239167082',
    appId: '1:339239167082:web:3c1db1c23ed79826f29613',
  },
  agGridLicenseKey:
    'CompanyName=XPO Logistics (Portland, OR 97209),LicensedGroup=xpo-ltl,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=45,LicensedProductionInstancesCount=0,AssetReference=AG-010153,ExpiryDate=7_October_2021_[v2]_MTYzMzU2MTIwMDAwMA==5929431bd3e2e336dd35e2d11cddb408',
  apiKeyGmap: 'AIzaSyBRpNoqaqpELjliulqyCfmzL2tCDAVDc6g',
};
