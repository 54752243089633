/**
 * Customer Notification API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type XrtApiApiClientSubfieldType = 0 | 1 | 2 | 3;

export const XrtApiApiClientSubfieldType = {
    NUMBER_0: 0 as XrtApiApiClientSubfieldType,
    NUMBER_1: 1 as XrtApiApiClientSubfieldType,
    NUMBER_2: 2 as XrtApiApiClientSubfieldType,
    NUMBER_3: 3 as XrtApiApiClientSubfieldType
};

