export interface GroupDetailsRequest {
  partnerGroupId: any;
  isInclusive?: boolean;
  partnerIdentifier: any;
  partnerGroups?: any;
}

export interface UserBulkRequest {
  username: any;
  createdBy?: string;
  statusAction?: string;
}

export interface PartnerUserRoleBulkUpdateRequest {
  username: any;
  partnerIdentifier: any;
  createdBy: string;
  userTypeCode?: any;
  enabledRoleList: any[];
  disabledRoleList: any;
}

export interface FeaturePermission {
  permissionId: number;
  permissionDetails: string;
  permissionName: string;
  isUIVisible: boolean;
  featureName: string;
}

export interface UserBulkResponse{
  isSuccess: boolean,
  message: string,
  isException: boolean,
  bulkUploadId: number
}
