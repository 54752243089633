export interface UserDetailsResponse {
  userDetails: UserDetailsClass;
  //accountWithGroups: AccountWithGroup[];
}

export interface UserRole {
  userRoleId: number;
  partnerRoleId: number;
  roleId: number;
  roleCode: string;
  roleName: string;
  roleDescription: string;
  partnerTypeId: number;
  businessUnitCode: string;
  modeCode: string;
  systemCode: string;
  isXpoOnly: boolean;
  applicationCode: string;
  userTypeCode: string;
  userTypeDesc: string;
}

export interface UserDetailsClass {
  userId: number;
  userName: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  isActive: boolean;
  preferredCurrencyCode: string;
  preferredCurrencyName: string;
  preferredLanguageCode: string;
  preferredLanguageName: string;
  preferredSystemOfMeasureCode: string;
  countryCode: string;
  lastLoggedInDate: string;
  communicationPreferenceId: number;
  companyName: string;
  address1: string;
  address2: string;
  stateCode: string;
  city: string;
  postalCode: string;
  userStatus: string;
}
