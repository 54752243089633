/**
 * Customer Notification API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { XrtApiApiClientXrtAggregation } from './xrtApiApiClientXrtAggregation';
import { XpoLtlCommonSearchSortDirection } from './xpoLtlCommonSearchSortDirection';
import { XpoLtlWebCustomerNotificationSubscriptionSharedModelsRequestsSubscriptionSearchFilterRequest } from './xpoLtlWebCustomerNotificationSubscriptionSharedModelsRequestsSubscriptionSearchFilterRequest';


export interface XpoLtlWebCustomerNotificationApiQueriesEventsGetNotificationRecipientsQuery { 
    pageSize?: number;
    pageNumber?: number;
    sortExpressions?: { [key: string]: XpoLtlCommonSearchSortDirection; } | null;
    filter?: { [key: string]: any; } | null;
    aggregations?: XrtApiApiClientXrtAggregation;
    notificationRecipientsFilterRequest?: XpoLtlWebCustomerNotificationSubscriptionSharedModelsRequestsSubscriptionSearchFilterRequest;
}

