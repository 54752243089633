import { Injectable } from '@angular/core';
import { PartnerMasterService } from '@sales-ops/sulu-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoAuthenticationService } from '@xpo-ltl/ngx-auth';
import { environment } from 'apps/edge-sales-ops/src/environments/environment';
import { catchError, filter, map, Observable, of, shareReplay, switchMap } from 'rxjs';
import { AppRouteAccess, AppRouteNames } from '../constants/routes.const';

@Injectable({ providedIn: 'root' })
export class UserAccessService {
  /** Emits when a user is fetched and if the user is an admin */
  readonly isAdmin$: Observable<boolean>;
  readonly isProd: boolean;
  readonly isUat: boolean;

  constructor(
    private partnerMasterService: PartnerMasterService,
    private authenticationService: XpoAuthenticationService,
    private configManagerService: ConfigManagerService
  ) {
    this.isProd = environment.production;
    this.isUat = environment.production && !this.configManagerService.getSetting('isProd');

    // ensuring user is loaded before making the user details call
    this.isAdmin$ = this.authenticationService.getUser$().pipe(
      filter((v) => !!v),
      switchMap(() => {
        return this.partnerMasterService.getUserDetails().pipe(
          catchError((error) => {
            console.error(error);
            return of(false);
          }),
          map((v) => !!v)
        );
      }),
      shareReplay()
    );
  }

  userHasAccessToRoute(route: AppRouteNames | string): Observable<boolean> {
    return this.isAdmin$.pipe(
      map((isAdmin) => {
        return (
          AppRouteAccess[route as AppRouteNames]?.({
            isAdmin,
            isProd: this.isProd,
            isUat: this.isUat,
          }) ?? false
        );
      })
    );
  }
}
