/**
 * Customer Notification API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';


// @ts-ignore
import { XpoLtlWebCustomerNotificationApiQueriesSubscriptionCustomLevelSubscriptionSearchQuery } from '../model/xpoLtlWebCustomerNotificationApiQueriesSubscriptionCustomLevelSubscriptionSearchQuery';
// @ts-ignore
import { XpoLtlWebCustomerNotificationApiQueriesSubscriptionGlobalLevelSubscriptionSearchQuery } from '../model/xpoLtlWebCustomerNotificationApiQueriesSubscriptionGlobalLevelSubscriptionSearchQuery';
// @ts-ignore
import { XpoLtlWebCustomerNotificationApiQueriesSubscriptionShipmentLevelSubscriptionSearchQuery } from '../model/xpoLtlWebCustomerNotificationApiQueriesSubscriptionShipmentLevelSubscriptionSearchQuery';
// @ts-ignore
import { XpoLtlWebCustomerNotificationApiQueriesSubscriptionSubscriptionSearchQuery } from '../model/xpoLtlWebCustomerNotificationApiQueriesSubscriptionSubscriptionSearchQuery';
// @ts-ignore
import { XpoLtlWebCustomerNotificationSharedModelsGlobalSubscription } from '../model/xpoLtlWebCustomerNotificationSharedModelsGlobalSubscription';
// @ts-ignore
import { XpoLtlWebCustomerNotificationSharedModelsRequestsCustomSubscriptionUpsert } from '../model/xpoLtlWebCustomerNotificationSharedModelsRequestsCustomSubscriptionUpsert';
// @ts-ignore
import { XpoLtlWebCustomerNotificationSharedModelsRequestsShipmentSubscriptionUpsert } from '../model/xpoLtlWebCustomerNotificationSharedModelsRequestsShipmentSubscriptionUpsert';
// @ts-ignore
import { XpoLtlWebCustomerNotificationSharedModelsResponseCustomSubscription } from '../model/xpoLtlWebCustomerNotificationSharedModelsResponseCustomSubscription';
// @ts-ignore
import { XpoLtlWebCustomerNotificationSharedModelsShipmentLevelSubscription } from '../model/xpoLtlWebCustomerNotificationSharedModelsShipmentLevelSubscription';
// @ts-ignore
import { XpoLtlWebCustomerNotificationSubscriptionSharedModelsRequestsUpsertSubscriptionRequest } from '../model/xpoLtlWebCustomerNotificationSubscriptionSharedModelsRequestsUpsertSubscriptionRequest';
// @ts-ignore
import { XpoLtlWebCustomerNotificationSubscriptionSharedModelsSubscriptionsElasticSubscription } from '../model/xpoLtlWebCustomerNotificationSubscriptionSharedModelsSubscriptionsElasticSubscription';

// @ts-ignore
import { BASE_PATH, CUST_BASE_PATH }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

    protected basePath = 'http://localhost';
    protected custBasePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional()@Inject(CUST_BASE_PATH) custBasePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        if (typeof this.configuration.custBasePath !== 'string') {
            if (typeof custBasePath !== 'string') {
                custBasePath = this.custBasePath;
            }
            this.configuration.custBasePath = custBasePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param xpoLtlWebCustomerNotificationApiQueriesSubscriptionCustomLevelSubscriptionSearchQuery 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public subscriptionCustomLevelElasticSearchPost(xpoLtlWebCustomerNotificationApiQueriesSubscriptionCustomLevelSubscriptionSearchQuery?: XpoLtlWebCustomerNotificationApiQueriesSubscriptionCustomLevelSubscriptionSearchQuery, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<XpoLtlWebCustomerNotificationSharedModelsResponseCustomSubscription>>;
    public subscriptionCustomLevelElasticSearchPost(xpoLtlWebCustomerNotificationApiQueriesSubscriptionCustomLevelSubscriptionSearchQuery?: XpoLtlWebCustomerNotificationApiQueriesSubscriptionCustomLevelSubscriptionSearchQuery, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<XpoLtlWebCustomerNotificationSharedModelsResponseCustomSubscription>>>;
    public subscriptionCustomLevelElasticSearchPost(xpoLtlWebCustomerNotificationApiQueriesSubscriptionCustomLevelSubscriptionSearchQuery?: XpoLtlWebCustomerNotificationApiQueriesSubscriptionCustomLevelSubscriptionSearchQuery, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<XpoLtlWebCustomerNotificationSharedModelsResponseCustomSubscription>>>;
    public subscriptionCustomLevelElasticSearchPost(xpoLtlWebCustomerNotificationApiQueriesSubscriptionCustomLevelSubscriptionSearchQuery?: XpoLtlWebCustomerNotificationApiQueriesSubscriptionCustomLevelSubscriptionSearchQuery, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<Array<XpoLtlWebCustomerNotificationSharedModelsResponseCustomSubscription>>(`${this.configuration.custBasePath}/Subscription/CustomLevelElasticSearch`,
            xpoLtlWebCustomerNotificationApiQueriesSubscriptionCustomLevelSubscriptionSearchQuery,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param xpoLtlWebCustomerNotificationApiQueriesSubscriptionSubscriptionSearchQuery 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public subscriptionElasticSearchPost(xpoLtlWebCustomerNotificationApiQueriesSubscriptionSubscriptionSearchQuery?: XpoLtlWebCustomerNotificationApiQueriesSubscriptionSubscriptionSearchQuery, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<XpoLtlWebCustomerNotificationSubscriptionSharedModelsSubscriptionsElasticSubscription>;
    public subscriptionElasticSearchPost(xpoLtlWebCustomerNotificationApiQueriesSubscriptionSubscriptionSearchQuery?: XpoLtlWebCustomerNotificationApiQueriesSubscriptionSubscriptionSearchQuery, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<XpoLtlWebCustomerNotificationSubscriptionSharedModelsSubscriptionsElasticSubscription>>;
    public subscriptionElasticSearchPost(xpoLtlWebCustomerNotificationApiQueriesSubscriptionSubscriptionSearchQuery?: XpoLtlWebCustomerNotificationApiQueriesSubscriptionSubscriptionSearchQuery, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<XpoLtlWebCustomerNotificationSubscriptionSharedModelsSubscriptionsElasticSubscription>>;
    public subscriptionElasticSearchPost(xpoLtlWebCustomerNotificationApiQueriesSubscriptionSubscriptionSearchQuery?: XpoLtlWebCustomerNotificationApiQueriesSubscriptionSubscriptionSearchQuery, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<XpoLtlWebCustomerNotificationSubscriptionSharedModelsSubscriptionsElasticSubscription>(`${this.configuration.custBasePath}/Subscription/ElasticSearch`,
            xpoLtlWebCustomerNotificationApiQueriesSubscriptionSubscriptionSearchQuery,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param xpoLtlWebCustomerNotificationApiQueriesSubscriptionGlobalLevelSubscriptionSearchQuery 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public subscriptionGlobalLevelElasticSearchPost(xpoLtlWebCustomerNotificationApiQueriesSubscriptionGlobalLevelSubscriptionSearchQuery?: XpoLtlWebCustomerNotificationApiQueriesSubscriptionGlobalLevelSubscriptionSearchQuery, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<XpoLtlWebCustomerNotificationSharedModelsGlobalSubscription>>;
    public subscriptionGlobalLevelElasticSearchPost(xpoLtlWebCustomerNotificationApiQueriesSubscriptionGlobalLevelSubscriptionSearchQuery?: XpoLtlWebCustomerNotificationApiQueriesSubscriptionGlobalLevelSubscriptionSearchQuery, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<XpoLtlWebCustomerNotificationSharedModelsGlobalSubscription>>>;
    public subscriptionGlobalLevelElasticSearchPost(xpoLtlWebCustomerNotificationApiQueriesSubscriptionGlobalLevelSubscriptionSearchQuery?: XpoLtlWebCustomerNotificationApiQueriesSubscriptionGlobalLevelSubscriptionSearchQuery, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<XpoLtlWebCustomerNotificationSharedModelsGlobalSubscription>>>;
    public subscriptionGlobalLevelElasticSearchPost(xpoLtlWebCustomerNotificationApiQueriesSubscriptionGlobalLevelSubscriptionSearchQuery?: XpoLtlWebCustomerNotificationApiQueriesSubscriptionGlobalLevelSubscriptionSearchQuery, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<Array<XpoLtlWebCustomerNotificationSharedModelsGlobalSubscription>>(`${this.configuration.custBasePath}/Subscription/GlobalLevelElasticSearch`,
            xpoLtlWebCustomerNotificationApiQueriesSubscriptionGlobalLevelSubscriptionSearchQuery,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param xpoLtlWebCustomerNotificationApiQueriesSubscriptionShipmentLevelSubscriptionSearchQuery 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public subscriptionShipmentLevelElasticSearchPost(xpoLtlWebCustomerNotificationApiQueriesSubscriptionShipmentLevelSubscriptionSearchQuery?: XpoLtlWebCustomerNotificationApiQueriesSubscriptionShipmentLevelSubscriptionSearchQuery, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<XpoLtlWebCustomerNotificationSharedModelsShipmentLevelSubscription>>;
    public subscriptionShipmentLevelElasticSearchPost(xpoLtlWebCustomerNotificationApiQueriesSubscriptionShipmentLevelSubscriptionSearchQuery?: XpoLtlWebCustomerNotificationApiQueriesSubscriptionShipmentLevelSubscriptionSearchQuery, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<XpoLtlWebCustomerNotificationSharedModelsShipmentLevelSubscription>>>;
    public subscriptionShipmentLevelElasticSearchPost(xpoLtlWebCustomerNotificationApiQueriesSubscriptionShipmentLevelSubscriptionSearchQuery?: XpoLtlWebCustomerNotificationApiQueriesSubscriptionShipmentLevelSubscriptionSearchQuery, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<XpoLtlWebCustomerNotificationSharedModelsShipmentLevelSubscription>>>;
    public subscriptionShipmentLevelElasticSearchPost(xpoLtlWebCustomerNotificationApiQueriesSubscriptionShipmentLevelSubscriptionSearchQuery?: XpoLtlWebCustomerNotificationApiQueriesSubscriptionShipmentLevelSubscriptionSearchQuery, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<Array<XpoLtlWebCustomerNotificationSharedModelsShipmentLevelSubscription>>(`${this.configuration.custBasePath}/Subscription/ShipmentLevelElasticSearch`,
            xpoLtlWebCustomerNotificationApiQueriesSubscriptionShipmentLevelSubscriptionSearchQuery,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param xpoLtlWebCustomerNotificationSharedModelsRequestsCustomSubscriptionUpsert 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public subscriptionUpsertCustomPut(xpoLtlWebCustomerNotificationSharedModelsRequestsCustomSubscriptionUpsert?: XpoLtlWebCustomerNotificationSharedModelsRequestsCustomSubscriptionUpsert, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public subscriptionUpsertCustomPut(xpoLtlWebCustomerNotificationSharedModelsRequestsCustomSubscriptionUpsert?: XpoLtlWebCustomerNotificationSharedModelsRequestsCustomSubscriptionUpsert, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public subscriptionUpsertCustomPut(xpoLtlWebCustomerNotificationSharedModelsRequestsCustomSubscriptionUpsert?: XpoLtlWebCustomerNotificationSharedModelsRequestsCustomSubscriptionUpsert, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public subscriptionUpsertCustomPut(xpoLtlWebCustomerNotificationSharedModelsRequestsCustomSubscriptionUpsert?: XpoLtlWebCustomerNotificationSharedModelsRequestsCustomSubscriptionUpsert, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.put<any>(`${this.configuration.custBasePath}/Subscription/UpsertCustom`,
            xpoLtlWebCustomerNotificationSharedModelsRequestsCustomSubscriptionUpsert,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param xpoLtlWebCustomerNotificationSharedModelsRequestsShipmentSubscriptionUpsert 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public subscriptionUpsertGlobalPut(xpoLtlWebCustomerNotificationSharedModelsRequestsShipmentSubscriptionUpsert?: XpoLtlWebCustomerNotificationSharedModelsRequestsShipmentSubscriptionUpsert, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public subscriptionUpsertGlobalPut(xpoLtlWebCustomerNotificationSharedModelsRequestsShipmentSubscriptionUpsert?: XpoLtlWebCustomerNotificationSharedModelsRequestsShipmentSubscriptionUpsert, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public subscriptionUpsertGlobalPut(xpoLtlWebCustomerNotificationSharedModelsRequestsShipmentSubscriptionUpsert?: XpoLtlWebCustomerNotificationSharedModelsRequestsShipmentSubscriptionUpsert, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public subscriptionUpsertGlobalPut(xpoLtlWebCustomerNotificationSharedModelsRequestsShipmentSubscriptionUpsert?: XpoLtlWebCustomerNotificationSharedModelsRequestsShipmentSubscriptionUpsert, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.put<any>(`${this.configuration.custBasePath}/Subscription/UpsertGlobal`,
            xpoLtlWebCustomerNotificationSharedModelsRequestsShipmentSubscriptionUpsert,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param xpoLtlWebCustomerNotificationSubscriptionSharedModelsRequestsUpsertSubscriptionRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public subscriptionUpsertPut(xpoLtlWebCustomerNotificationSubscriptionSharedModelsRequestsUpsertSubscriptionRequest?: Array<XpoLtlWebCustomerNotificationSubscriptionSharedModelsRequestsUpsertSubscriptionRequest>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public subscriptionUpsertPut(xpoLtlWebCustomerNotificationSubscriptionSharedModelsRequestsUpsertSubscriptionRequest?: Array<XpoLtlWebCustomerNotificationSubscriptionSharedModelsRequestsUpsertSubscriptionRequest>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public subscriptionUpsertPut(xpoLtlWebCustomerNotificationSubscriptionSharedModelsRequestsUpsertSubscriptionRequest?: Array<XpoLtlWebCustomerNotificationSubscriptionSharedModelsRequestsUpsertSubscriptionRequest>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public subscriptionUpsertPut(xpoLtlWebCustomerNotificationSubscriptionSharedModelsRequestsUpsertSubscriptionRequest?: Array<XpoLtlWebCustomerNotificationSubscriptionSharedModelsRequestsUpsertSubscriptionRequest>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.put<any>(`${this.configuration.custBasePath}/Subscription/Upsert`,
            xpoLtlWebCustomerNotificationSubscriptionSharedModelsRequestsUpsertSubscriptionRequest,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param xpoLtlWebCustomerNotificationSharedModelsRequestsShipmentSubscriptionUpsert 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public subscriptionUpsertShipmentPut(xpoLtlWebCustomerNotificationSharedModelsRequestsShipmentSubscriptionUpsert?: XpoLtlWebCustomerNotificationSharedModelsRequestsShipmentSubscriptionUpsert, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public subscriptionUpsertShipmentPut(xpoLtlWebCustomerNotificationSharedModelsRequestsShipmentSubscriptionUpsert?: XpoLtlWebCustomerNotificationSharedModelsRequestsShipmentSubscriptionUpsert, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public subscriptionUpsertShipmentPut(xpoLtlWebCustomerNotificationSharedModelsRequestsShipmentSubscriptionUpsert?: XpoLtlWebCustomerNotificationSharedModelsRequestsShipmentSubscriptionUpsert, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public subscriptionUpsertShipmentPut(xpoLtlWebCustomerNotificationSharedModelsRequestsShipmentSubscriptionUpsert?: XpoLtlWebCustomerNotificationSharedModelsRequestsShipmentSubscriptionUpsert, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.put<any>(`${this.configuration.custBasePath}/Subscription/UpsertShipment`,
            xpoLtlWebCustomerNotificationSharedModelsRequestsShipmentSubscriptionUpsert,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
