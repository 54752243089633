/**
 * Customer Notification API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { XpoConnectBillingServiceModelsV2PaymentPaymentDateRange } from './xpoConnectBillingServiceModelsV2PaymentPaymentDateRange';


export interface XpoConnectBillingServiceModelsV2PaymentPaymentSearchQueryRequest { 
    q?: string | null;
    isPartialPayment?: boolean | null;
    paymentMethod?: string | null;
    paymentDateRange?: XpoConnectBillingServiceModelsV2PaymentPaymentDateRange;
}

