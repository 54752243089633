import { SystemCode } from '../const/partner-master.const';
import { ResponseObject } from './response-object';

export interface FetchPartnersRequest {
  systemCodes?: SystemCode[];
  sortExpressions?: never[];
  childSystemCodes?: never[];
  lastUpdatedOnFrom?: string;
  lastUpdatedOnTo?: string;
  q?: any;
  page?: number;
  pageNumber?: number;
  pageSize: number;
  filter?: any;
}

export interface FetchPartnersResponseObject extends ResponseObject {
  items: PartnerObject[];
}

export enum PartnerGroupId {
  Corporate = 3,
  PnD = 2,
  BillTo = 1,
}

export interface PartnerObject {
  childPartners?: any;
  partnerId: number; // TODO: (ancel) remove me when latest PM is in prod
  childPartnerIdentifiers?: string[];
  parentPartnerIdentifierHierarchy?: string[];
  tenantId?: number;
  partnerParentId?: number;
  partnerTypeId?: number;
  partnerIdentifier?: string;
  systemCode?: string;
  dbaName?: string;
  displayName: string;
  countryCd: string;
  createdOn?: string;
  lastUpdatedOn?: string;
  partnerStatusId?: number;
  nodeValue?: string;
  level?: number;
  acctInstId: string;
  custLocationFuncId?: number;
  madCode: string;
  madcode?: string; // todo?: remove when api is fixed
  isDeleted?: boolean;
  partnerStatus?: string;
  parentPartnerIdentifier?: string;
  isChild?: boolean;
  status: string;
  isPartnerCreate?: boolean;
  addressLine1: string;
  addressTypeCd: string;
  cityName: string;
  postalCd: string;
  stateCd: string;
  partnerTypeName?: string;
  group?: {
    groupId?: PartnerGroupId;
    groupName?: string;
  };
  accessLevel?: number;
  roleName?: string;
  flag?: string;
}

export interface Accounts {
  partnerId: number; // TODO: (ancel) remove me when latest PM is in prod
  displayName: string;
  partnerIdentifier: string;
  madcode: string;
  acctInsId: string;
  addressLine1: string;
  addressTypeCd: string;
  cityName: string;
  countryCd: string;
  stateCd: string;
  postalCd: string;
  status: string;
  userId?: number;
  username?: string;
  groupTypeId?: number;
  groupName?: string;
  flag?: string;
  custLocationFuncId?: number;
}

export interface UserAccountRequest {
  locations: {
    locationId?: number;
    accessLevel?: number;
    requestType?: string;
  }[];
  userName: string;
  isApproved: boolean;
}

export interface GroupType {
  groupTypeId?: number;
  groupTypeName?: string;
  createdBy?: string;
  createdOn?: string;
  lastUpdatedBy?: string;
  lastUpdatedOn?: string;
}
