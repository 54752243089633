/**
 * Customer Notification API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type XrtApiApiClientDateInterval = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;

export const XrtApiApiClientDateInterval = {
    NUMBER_0: 0 as XrtApiApiClientDateInterval,
    NUMBER_1: 1 as XrtApiApiClientDateInterval,
    NUMBER_2: 2 as XrtApiApiClientDateInterval,
    NUMBER_3: 3 as XrtApiApiClientDateInterval,
    NUMBER_4: 4 as XrtApiApiClientDateInterval,
    NUMBER_5: 5 as XrtApiApiClientDateInterval,
    NUMBER_6: 6 as XrtApiApiClientDateInterval,
    NUMBER_7: 7 as XrtApiApiClientDateInterval
};

