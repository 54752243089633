/**
 * Customer Notification API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SystemDayOfWeek } from './systemDayOfWeek';
import { XpoLtlWebCustomerNotificationSharedModelsEvents } from './xpoLtlWebCustomerNotificationSharedModelsEvents';


export interface XpoLtlWebCustomerNotificationSharedModelsRequestsSubscriptionSearchFilterRequestBackend { 
    q?: string | null;
    subscriptionName?: string | null;
    eventIds?: XpoLtlWebCustomerNotificationSharedModelsEvents;
    appIds?: Array<string> | null;
    eventTypes?: Array<string> | null;
    customerNumbers?: Array<string> | null;
    madCodes?: Array<string> | null;
    proNumbers?: Array<string> | null;
    usernames?: Array<string> | null;
    isVisibleToUser?: boolean | null;
    isActive?: boolean | null;
    isDeleted?: boolean | null;
    matchNullValues?: boolean;
    daysOfWeek?: Array<SystemDayOfWeek> | null;
    timesOfDay?: Array<number> | null;
}

