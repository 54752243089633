import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SnackBarData } from './alerts.interface';

@Component({
  selector: 'eso-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
})
export class AlertsComponent implements OnInit {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData) {}

  ngOnInit() {}

  get getIcon() {
    switch (this.data.type) {
      case 'success':
        return 'done';
      case 'error':
        return 'error';
      default:
        return 'done';
    }
  }

  close(): void {
    this.data.snackBar.dismiss();
  }
}
