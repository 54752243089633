/**
 * Customer Notification API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { XrtApiApiClientSubfieldType } from './xrtApiApiClientSubfieldType';
import { XrtApiApiClientDateInterval } from './xrtApiApiClientDateInterval';


export interface XrtApiApiClientSubField { 
    name?: string | null;
    readonly dateInterval?: string | null;
    dateIntervalType?: XrtApiApiClientDateInterval;
    minimumDocumentCount?: number | null;
    size?: number;
    missing?: string | null;
    subFields?: Array<XrtApiApiClientSubField> | null;
    readonly type?: string | null;
    subfieldType?: XrtApiApiClientSubfieldType;
}

