/**
 * Customer Notification API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { XpoLtlWebCustomerNotificationSubscriptionSharedModelsIApplicationReference } from './xpoLtlWebCustomerNotificationSubscriptionSharedModelsIApplicationReference';
import { XpoLtlWebCustomerNotificationSubscriptionSharedModelsRequestsBroadcastRecipientRequest } from './xpoLtlWebCustomerNotificationSubscriptionSharedModelsRequestsBroadcastRecipientRequest';
import { XpoLtlWebCustomerNotificationSharedModelsEventReferenceObj } from './xpoLtlWebCustomerNotificationSharedModelsEventReferenceObj';


export interface XpoLtlWebCustomerNotificationSharedModelsRequestsShipmentSubscriptionUpsert {
    events?: Array<XpoLtlWebCustomerNotificationSharedModelsEventReferenceObj> | null;
    applicationReference?: XpoLtlWebCustomerNotificationSubscriptionSharedModelsIApplicationReference;
    applicationReferenceType?: string | null;
    broadcastRecipientRequest?: Array<XpoLtlWebCustomerNotificationSubscriptionSharedModelsRequestsBroadcastRecipientRequest> | null;
    username?: string | null;
    isCustom?: boolean;
    isResetSettings?: boolean;
}

