/**
 * Customer Notification API V1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { XpoLtlWebCustomerNotificationSubscriptionSharedModelsIApplicationReference } from './xpoLtlWebCustomerNotificationSubscriptionSharedModelsIApplicationReference';
import { XpoLtlWebCustomerNotificationSubscriptionSharedModelsRequestsBroadcastRecipientRequest } from './xpoLtlWebCustomerNotificationSubscriptionSharedModelsRequestsBroadcastRecipientRequest';


export interface XpoLtlWebCustomerNotificationSubscriptionSharedModelsRequestsUpsertSubscriptionRequest { 
    subscriptionId?: number;
    subscriptionName?: string | null;
    eventId?: number;
    applicationReference?: XpoLtlWebCustomerNotificationSubscriptionSharedModelsIApplicationReference;
    broadcastRecipientRequest?: Array<XpoLtlWebCustomerNotificationSubscriptionSharedModelsRequestsBroadcastRecipientRequest> | null;
    userName?: string | null;
    deleted?: boolean;
    isVisibleToUser?: boolean;
    isActive?: boolean;
}

