export class ListFilterModel {
  controlName?: string;
  controlType?:
    | 'text'
    | 'divider'
    | 'select'
    | 'multiSelect'
    | 'multiSelectWithSearch'
    | 'autoCompleteWithMultiSelect'
    | 'datePicker';
  valueType?: string;
  placeHolder?: string;
  label?: string;
  maxDate?: Date;
  options?: any;
  defaultValue?: any;
  searchOptions?: any;
  key?: string;
  value?: string;
  hintTemplate?: any;
  flex?: string | null;
}
