import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserAccessService } from '../services/user-access.service';

@Injectable({ providedIn: 'root' })
export class UserRouteAccessGuard implements CanActivate {
  static readonly UserRouteAccessRouteKey = 'UserRouteAccessRouteKey';
  constructor(private userAccessService: UserAccessService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return route.routeConfig?.path
      ? this.userAccessService.userHasAccessToRoute(route.routeConfig.path)
      : false;
  }
}
